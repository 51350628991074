import "../styles/globals.css";
import Router from "next/router";
import ProgressBar from "@badrap/bar-of-progress";
import Head from "next/head";
import { Toaster } from "react-hot-toast";
import App, { AppProps, AppContext } from 'next/app'

const progress = new ProgressBar({
  size: 2,
  color: "#ffffff",
  className: "z-50",
  delay: 100,
});

Router.events.on("routeChangeStart", progress.start);
Router.events.on("routeChangeComplete", progress.finish);
Router.events.on("routeChangeError", progress.finish);

MyApp.getInitialProps = async (appContext) => {
    // calls page's `getInitialProps` and fills `appProps.pageProps`
    const appProps = await App.getInitialProps(appContext)

    return {
        pageProps: {
            ...appProps.pageProps,
            navigation: [],
        },
    }
}

function MyApp({ Component, pageProps }) {
    return (
    <>
      <div>
        <Toaster />
      </div>
      <Component {...pageProps} />
    </>
  );
}

export default MyApp;
